import React from "react";
import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Image } from "./Image";
import { CircularProgress } from "@mui/material";
import Error from "./Error";
import Loader from "./Loader";

export const Team = (props) => {
const dataReturned=useQuery(
  {
    queryKey:["teamData"],
    queryFn:()=>
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/team-member/?website=Company`)
    .then((res)=>res.data),
  }
)

if(dataReturned.data){
  console.log(dataReturned.data);
}


if(dataReturned.isLoading){
  return(
    <Loader/>
  )
}

if(dataReturned.isError){
  return(
    <Error/>
  )
}

  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Meet the Team</h2>
          <p>
            INTRODUCING OUR DYNAMIC TEAM
          </p>
        </div>
        <div id="row">
          {dataReturned.data
            ? dataReturned.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-3 col-sm-6 team">
                  <div className="thumbnail">
                    {" "}
                     <Image
                      title={d.full_name}
                      email={d.email}
                      largeImage={process.env.REACT_APP_BACKEND_URL+d.image}
                      smallImage={process.env.REACT_APP_BACKEND_URL+d.image}
                      linkedIn={d.linkedin_url}
                    />

                    <div className="caption">
                      <h4>{d.full_name}</h4>
                      <p>{d.description}</p>
                      <p>{d.email}</p>
                      <a target={"_blank"} href={d.facebook_url}>
                  <i className="fa fa-facebook-square"> Facebook </i> 
                      </a>
                      <br></br>
                      <br></br>
                       <a target={"_blank"} href={d.linkedin_url}>
                  <i className="fa fa-linkedin-square"> LinkedIn</i> 
                      </a>
                      <br></br>
                      <br></br>
                       <a target={"_blank"} href={d.twitter_url}>
                  <i className="fa fa-linkedin-square"> Twitter</i> 
                      </a>

                    </div>
                  </div>
                </div>
              ))
            : <CircularProgress/>}
        </div>
      </div>
    </div>
  );
};

