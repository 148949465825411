import { Image } from "./Image";
import React from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import Loader from "./Loader";
import Error from "./Error";
export const Gallery = (props) => {

 const galleryData = useQuery({
    queryKey: ['galleryData'],
    queryFn: () =>
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/gallery/?website=Company`)
    .then((res) =>res.data,
      ),
  })


if(galleryData.isLoading){
  return(
    <Loader/>
  )
}

if(galleryData.isError){
  return(
    <Error/>
  )
}




if(galleryData.data){
  console.log(galleryData.data);
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Gallery</h2>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {galleryData.data
              ? galleryData.data.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6"
                  >
                    <Image
                      title={d.name}
                      largeImage={process.env.REACT_APP_BACKEND_URL+d.image}
                      smallImage={process.env.REACT_APP_BACKEND_URL+d.image}
                    />
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div>
    </div>
  );
}
};
