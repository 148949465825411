import React from "react";
import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import MainCircularProgress from "./MainCircularProgress";
import Error from "./Error";
import Loader from "./Loader";
import '../data/aboutPage.css'
import parse from "html-react-parser"
export const About = (props) => {

const dataReturned=useQuery(
  {
    queryKey:["aboutUsData"],
    queryFn:()=>
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/about-us/?website=Company`)
    .then((res)=>res.data),
  }
)


if(dataReturned.isLoading){
  return(
    <Loader/>
  )
}

if(dataReturned.isError){
  return(
    <Error/>
  )
}

  return (
    <div className="aboutDiv">
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="row-xs-20" style={{display:"flex", justifyContent:"center"}}>
            {" "}
          {dataReturned.data ? dataReturned.data.map((d)=>(
            <img src={process.env.REACT_APP_BACKEND_URL+d.logo} className="img-responsive" alt="" />
          )):<CircularProgress/>
          }
          </div>
          <div className="col-xs-20 col-md-20">
            <div className="about-text">
              <h2>About Us</h2>
              <p>{dataReturned.data ? parse(dataReturned.data[0].description) : <MainCircularProgress/>}</p>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

