import { CircularProgress } from "@mui/material";

function Button({ mutation }) {
  return (
    <button
      type="submit"
      className="btn btn-custom btn-lg"
      disabled={mutation.isPending ? true : false}
      style={{ minWidth: 200 }}
    >
      {mutation.isPending ? (
        <CircularProgress style={{ color: "white" }} size={20} />
      ) : (
        "Send Message"
      )}
    </button>
  );
}
export default Button;

